<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title  mb-0">
                {{ chapter.title }}
              </h2>
              <span
                v-if="chapter.onlyAdmin"
                class="badge bg-light-info"
              >
                admin
              </span>
              <span
                v-else
                class="badge bg-light-success"
              >
                public
              </span>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              @click="$router.go(-1)"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="user.roles.includes('super-admin')"
              class="btn btn-icon btn-primary ms-1"
              @click="$router.push({name: 'knowledge-base.edit', params: { id: chapter.id }})"
            ><i data-feather="edit-2" /></a>
          </div>
        </div>
      </div>
      <div class="content-body">

        <!-- Knowledge base question Content  -->
        <section id="knowledge-base-question">
          <div class="row">
            <div class="col-lg-3 col-md-5 col-12 order-2 order-md-1">
              <div class="card">
                <div class="card-body">
                  <h6 class="kb-title">
                    <i
                      data-feather="info"
                      class="font-medium-4 me-50"
                    /><span>{{ section.title }}</span>
                  </h6>

                  <ul class="list-group list-group-circle mt-2">
                    <li
                      v-for="(otherChapter, index2) in section.chapters"
                      :key="index2"
                      class="list-group-item"
                      :class="otherChapter.id == chapter.id ? 'active' : ''"
                    >
                      <a
                        href="#"
                        class="text-body"
                        @click="changeChapter(otherChapter.id)"
                      >{{ otherChapter.title }}</a>
                      <span
                        v-if="otherChapter.onlyAdmin == true"
                        class="badge bg-light-info"
                      >admin</span>
                      <span
                        v-if="otherChapter.onlyAdmin == false"
                        class="badge bg-light-success"
                      >public</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-7 col-12 order-1 order-md-2">
              <div class="card">
                <div
                  class="card-body"
                  v-html="contentWithFilter != undefined ? contentWithFilter : chapter.content"
                />
              </div>
              <hr>
              <AttachedForm
                v-if="chapter.form"
                :type="'KnowledgeBaseChapter'"
                :model-id="chapter.id"
              />

              <div
                v-if="attached"
                class="page-actions"
              >
                <button
                  v-if="!sending"
                  id="type-success"
                  class="btn btn-success"
                  @click="saveRequestForm"
                >
                  <i data-feather="save" />
                  <span class="ms-25 align-middle">Save form</span>
                </button>
                <button
                  v-else
                  class="btn btn-outline-success"
                  type="button"
                  disabled=""
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span class="ms-25 align-middle">Saving...</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        <!-- Knowledge base question Content ends -->
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    AttachedForm,
  },
  data() {
    return {
      chapterId: this.$route.params.id,
      contentWithFilter: undefined,
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      chapter: 'knowledgeBase/chapter',
      section: 'knowledgeBase/section',
      searchedWords: 'knowledgeBase/searchedWords',
      user: 'auth/admin',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    await this.$store.dispatch('knowledgeBase/fetchChapter', this.chapterId)
    await this.$store.dispatch('knowledgeBase/fetchSection', this.chapter.section.id)
    this.checkSearchedWords()
  },
  methods: {
    async saveRequestForm() {
      this.sending = true
      await this.$store.dispatch('knowledgeBase/updateChapter', { id: this.chapterId, data: { ...this.chapter, attached_form: this.attached } })
      this.sending = false
      Vue.swal('Form answered', 'Form answered successfully', 'success')
    },
    changeChapter(newChapterId) {
      this.$store.dispatch('knowledgeBase/fetchChapter', newChapterId)
      this.checkSearchedWords()
    },
    checkSearchedWords() {
      if (this.searchedWords !== '') {
        this.contentWithFilter = this.chapter.content.replace(this.searchedWords, `<mark>${this.searchedWords}</mark>`)
      }
    },
  },
}
</script>
